@tailwind base;
@tailwind components;
@tailwind utilities;

.spiderBooking-form__actions-toolbar button.spiderBooking-form__button {
    background-color: #0059FF !important;
    font-size: 1rem !important;
}


.spiderBooking label,
.spiderBooking  input,
.spiderBooking  span {
    font-size: 1rem !important;;
    font-family: 'Roboto', sans-serif !important;
}
